:root {
  --menu: #3e79f7;
  --menubg: #e6f7ff;
  --dark: rgb(47, 47, 47);
  --dropdawnbg: #fafafa;
  --background: #fafafb;
  --tab-next: #0d6efd;
  --subscribe: #18a695;
  --subscribe-text: #233342;
  --white: #ffffff;
  --black: rgba(0, 0, 0, 0.66);
  --danger: #ff0004;
  --chat-date: rgba(164, 164, 164, 0.46);
  --chat-bg: #f3f3f0;
  --curier-sms-bg: #f0f2f5;
  --user-sms-bg: #fff;
  --primary-text: #000;
  --ingrediend-text: rgb(0, 0, 0);
  --ingrediend-border: rgba(0, 0, 0, 0.05);
  --primary: #3e79f7;
  --sidebar-active: rgba(62, 121, 247, 0.1);
  --primary-hover: #699dff;
  --border-color: #e6ebf1;
  --grey: #ededed;
  --dark-background: #283142;
  --dark-grey: #4d5b75;
  --light-text: #d6d7dc;
  --dark-blue: #1b2531;
  --dark-red: #ff6b72;
  --dark-muted-text: rgba(114, 132, 154, 0.9);
  --dark-primary-hover: rgba(62, 121, 247, 0.1);
  --bg-color: #323d52;
}
