// navbar
.ant-layout-rtl {
  .navbar-nav {
    .menu-collapse {
      left: 10px;
      right: auto;
    }
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 100vh;

  // overflow-y: auto;
  // overflow-x: hidden;
  &:hover {
    .menu-collapse {
      visibility: visible;
      z-index: 2;
    }
  }

  .menu-collapse {
    visibility: hidden;
    z-index: -1;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      font-size: 20px;
    }

    &:hover {
      background-color: var(--grey);
    }
  }

  .menu-unfold {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: var(--primary);
    }
  }

  .icon-button {
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;
    transition: all 0.3s ease;

    .default-lang {
      font-size: 16px;
      color: var(--grey-dark);
      text-transform: uppercase;
      margin-left: 12px;
      mask-type: -5px;
    }

    .globalOutlined {
      width: 24px !important;
      height: 24px !important;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        color: var(--menu);

        svg {
          color: var(--menu);
        }
      }
    }
  }

  .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
  }

  .accordion-button:not(.collapsed) {
    background: white !important;
    box-shadow: none;
  }

  .sidebar-brand {
    position: relative;
    min-height: 70px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    padding: 0 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
    display: flex;
    align-items: center;

    .sidebar-logo {
      width: 60px;
      height: 60px;
      padding: 8px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top center;
    }

    .sidebar-brand-text {
      .user-name {
        width: 100%;
        display: flex;
        font-size: 12px;
        justify-content: center;
        margin-bottom: 4px;
      }

      .user-status {
        font-size: 11px;
        margin-bottom: 0;
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .sidebar-brand {
      padding: 0;
      justify-content: center;

      .sidebar-brand-text {
        display: none;
      }
    }

    .menu-collapse {
      display: none;
    }
  }

  .isActive,
  .logout {
    color: var(--dark) !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .box {
      padding: 4.8px 24px;
      display: flex;
      text-align: left;
      width: 14rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.5s ease;

      i {
        color: var(--dark);
        font-size: 1.1rem;
        margin-right: 0.8rem;
        display: flex;
        align-items: center;

        svg {
          font-size: 17px;
        }
      }

      span {
        font-size: 0.85rem;
        display: inline;
        color: var(--dark);
      }
    }

    &:hover {
      text-decoration: none;

      i {
        color: var(--menu);
        transition: color 0.5s ease;
      }

      span {
        color: var(--menu);
        transition: color 0.5s ease;
      }
    }

    &.active {
      color: var(--menu) !important;
      background: var(--sidebar-active) !important;
      border-right: 3px solid var(--menu);

      i {
        color: var(--menu);
        transition: color 0.5s ease;
      }

      span {
        color: var(--menu);
        transition: color 0.5s ease;
      }
    }
  }

  .nav-tut {
    color: rgba(0, 0, 0, 0.63) !important;
    padding: 0 0 18px 24px;
  }

  .nav-item {
    transition: background, width 0.5s ease;
    margin: 4px 0 8px 0 !important;
    padding: 0 !important;
    height: 40px;

    .nav-link {
      display: flex;
      text-align: left;
      padding: 0.4rem 1.5rem;
      width: 100%;
      font-weight: 500;
      transition: all 0.5s ease;
      cursor: pointer;

      i {
        color: var(--dark);
        font-size: 1rem;
        margin-right: 0.8rem;
        display: flex;
        align-items: center;
      }

      span {
        font-size: 0.85rem;
        display: inline;
        color: var(--dark);
      }

      &[data-toggle='collapse'] {
        color: var(--dark);
      }

      &:hover {
        text-decoration: none;

        i {
          color: var(--menu);
          transition: color 0.5s ease;
        }

        span {
          color: var(--menu);
          transition: color 0.5s ease;
        }
      }
    }

    .collapse {
      transition: all 0.5s ease;
    }

    .col {
      margin: 0 !important;
      padding: 0 !important;
    }

    .row,
    .col .collapse {
      background: var(--white);

      .collapse-inner {
        background: var(--white);
        margin: 0 !important;
        padding: 0 !important;
        max-width: 102%;
        width: 102%;

        .active {
          background: var(--sidebar-active) !important;
        }

        .collapse-item {
          padding: 0.75rem 1rem;
          margin: 0 0 0 15px;
          display: block;
          width: 100%;
          color: #3a3b45;
          text-decoration: none;
          border-radius: 0.35rem;
          white-space: nowrap;

          &:hover {
            background: transparent;
            color: var(--menu);
          }
        }
      }
    }
  }

  .showMenuBar {
    margin: 20px auto;
    background: var(--dark) !important;
    border: none;
    opacity: 0.9;

    .left,
    .right {
      color: white;
    }
  }
}

// dashboard app toggle change
.sidebar.toggled #sidebarToggle::after {
  content: '\f105';
  margin-left: 0.25rem;
  transition: width 0.2s linear;
}

.sidebar.toggled #sidebarToggle::after {
  color: var(--white);
  margin-left: 13px;
  width: 2.2rem;
  height: 2.2rem;
}

.sidebar.toggled {
  overflow: visible;
  width: 6.5rem !important;
  transition: width 0.2s linear;

  .accordion-button::after {
    display: none;
  }

  .sidebar-brand {
    display: block !important;
    padding: 15px 1rem 70px 1rem;
    margin-bottom: 2rem;
    text-align: center;
    transition: width 0.2s linear;

    .sidebar-logo {
      width: 60px;
      padding: 8px;
      border-radius: 50%;
    }

    .sidebar-brand-text {
      .user-name {
        width: 100%;
        display: flex;
        font-size: 12px;
      }

      .user-status {
        font-size: 12px;
        display: block;
      }
    }
  }

  .nav-tut {
    display: none;
  }

  .nav-item {
    margin: 0;

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0;

      .text {
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        display: none;
      }

      .icon-log-out {
        font-size: 23px;
      }

      .icon-menu {
        font-size: 23px;
      }

      i {
        display: block;
        margin-right: 0;
        font-size: 25px !important;
      }
    }

    .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: 6.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      .text {
        width: 100%;
        font-size: 14px !important;
        display: none;
      }

      .icon-menu {
        font-size: 23px;
      }

      i {
        margin-right: 0;
      }

      &[data-toggle='collapse']::after {
        display: none;
      }
    }

    .collapse {
      position: absolute;
      left: calc(6.5rem + 1.5rem / 2);
      z-index: 1;
      top: 2px;
      animation-name: growIn;
      animation-duration: 200ms;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }
}

.sidebar .nav-item {
  position: relative;
}

@media (min-width: 768px) {

  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }

  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }

  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }

  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }

  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }
}

[data-theme='dark'] {
  .navbar-nav {
    .menu-collapse:hover {
      background-color: var(--dark-grey);
    }
  }
}